var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import getLocaleUrl from "../../router/getLocaleUrl";
var SHOW_HASH = "#language-chosen";
export default Vue.extend({
    computed: __assign(__assign({}, mapState(["languages"])), { show: function () {
            return this.$route.hash !== SHOW_HASH;
        } }),
    methods: __assign({ onClick: function (language) {
            this.setLanguage(language);
            window.location.href = getLocaleUrl(this.$route, language) + SHOW_HASH;
        } }, mapMutations(["setLanguage"])),
});
